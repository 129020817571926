import { v5 as uuidv5 } from 'uuid';
import { StreamChat } from 'stream-chat';
import auth from '@/api/auth';
import store from '@/store';
import env from '@/api/env';

import _ from 'lodash';

export default {

	async login()
	{

		const id = store.getters['user/id'];

		if(this.client?.user?.id === id)
		{
			//already connected for this user
			return;
		}

		this.disconnect();

		const streamKey = env.streamKey();
		const token = await auth.chatLogin();
		this.client = StreamChat.getInstance(streamKey, {
			timeout: 6000,
		});

		if (window.Cypress)
			this.client.wsBaseURL =
				process.env.VUE_APP_LOCAL_WS || "ws://localhost:3031";

		const profileImage = store.getters['user/profileImage'];
		const fullName = store.getters['user/fullName'];
		const userId = store.getters['user/id'];
		
		// Listen to new messages
		this.client.on(eventListener);

		await this.client.connectUser({ id, name: fullName, image: profileImage }, token );

		if(store.getters['mobile/isK4App'])
		{
			
			//reload settings to get push tokens - they arent saved until Bridge is initialized
			await store.dispatch('settings/load');
			
			const pushToken = store.getters['settings/pushToken'];
			await this.client.addDevice(pushToken, 'firebase', userId);
		}

	},


	disconnect()
	{
		if(this.client)
		{
			//clean up old connections
			this.client.disconnect()
		}
	},

	async getChannels(limit = 50)
	{

		const id = store.getters['user/id'];

		const filter = { type: 'messaging', members: { $in: [ id ] } }
		const sort = [{ last_message_at: -1 }];

		return this.client.queryChannels(filter, sort, { watch: false, state: true, message_limit: limit });
	},

	async getChannel(id, limit = 50)
	{
		const filter = { type: 'messaging', id: { $eq: id } };
		const sort = [{ last_message_at: -1 }];
		const channels = await this.client.queryChannels(filter, sort, { watch: true, state: true, message_limit: limit });
		return channels[0];
	},

	async searchUsers(query)
	{
		const isResident = store.getters['user/isResident'];

		const id = query.id && { $autocomplete: query.id }
		const name = query.name && { $autocomplete: query.name }

		const { teams } = this.client.user;

		if(!teams)
		{
			return [];
		}

		//query members of all teams this user is part of
		const queriedUsers = await teams.reduce(
			async (partialUsers, team) => {
				const response = await this.client.queryUsers(
					{ id, name, teams: { $contains: team } },
					{ limit: 100 }
				)
				return [...(await partialUsers), ...response.users]
			},
			[]
		);

		//remove opt out users and ourself
		return _.chain(queriedUsers)
			.filter((user) => {
				return (
					!(user.optOutOfDirectory && isResident) &&
					user.id !== this.client.user.id
				);
			})
			.uniqBy("id")
			.value();
	},

	async createChannel(member)
	{
		const { id, teams } = this.client.user;

		const channelId = this.generateChannelId([id, member.id]);

		//check for existing
		const common = _.intersection(member.teams, teams);

		const channel = await this.client.channel('messaging', channelId, {
			members: [member.id,id],
			team: common[0],
		})

		await channel.watch();
		await channel.show();
		await this.client.recoverState();

		return channel;
	},

	async deleteMessage(id)
	{
		return this.client.deleteMessage(id);
	},

	async updateMessage(message)
	{
		return this.client.updateMessage(message);
	},

	generateChannelId(ids)
	{
		const id = ids.sort().join('-')
		return uuidv5(id, uuidv5.URL);
	},

	client: null


}

const eventListener = (event) => {
	const updateTypes = ["notification.mark_read", "notification.message_new"];
	if (updateTypes.includes(event.type)) {
		store.dispatch("chat/updateUnreadCount", event.unread_count);
	}
};
